<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <img
            width="85%"
            src="../../assets/sobre_cultura_ponte_do_gama_01.png"
          />
          <img
            class="mt-2"
            width="85%"
            src="../../assets/sobre_cultura_ponte_do_gama_02.png"
          />
          <p style="font-size: 0.5em; text-align: center; width: 85%;">
            Peças artesanais de couro para montaria, produzidas pelo Sr. José de
            Jair. Fotos: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Manifestações tradicionais religiosas,culturais e socioeconômicas"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                As celebrações e festas religiosas de Ponte do Gama possuem como
                lugar de ocorrência a Capela de Nossa Senhora Aparecida e o
                centro comunitário. Entre as festividades tradicionais de
                natureza religiosa são relevantes para a comunidade a Festa de
                Nossa Senhora Aparecida, Festa de Santa Cruz e Festa de São
                Vicente, destacando-se ainda a novena de Natal. Após a
                construção da Capela na área de planície, a Festa de Santa Cruz
                deixou de ser realizada no alto do morro, no mês de maio. Os
                fiéis desciam com a cruz em procissão, cantando e rezando.
              </p>
              <p>
                Integrada às festividades religiosas, evidencia-se a cavalgada,
                festa tradicional popular que reúne os próprios moradores, bem
                como participantes das comunidades vizinhas, promovendo um
                grande encontro sócio-cultural do território. Conciliadas também
                com as manifestações religiosas, estão as partidas de futebol,
                que, como as cavalgadas, promovem encontros entre a população
                local e da vizinhança.
              </p>
              <p>
                Fazem parte ainda dos eventos sociais o concurso de forró, a
                festa de fim de ano, a fogueira de São João, bem como a “festa
                dos gamenses ausentes”, em que ex-moradores de Ponte do Gama
                ausentes da localidade retornam para festejar o encontro com
                parentes e amigos. Era comum os moradores reunirem-se no centro
                comunitário para almoçar depois de jogar bola ou nadar nas
                cachoeiras.
              </p>
            </template>
          </Card>

          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Em relação aos saberes tradicionais, na culinária local
                evidencia-se a feijoada da cavalgada, o café da manhã dos
                gamenses ausentes, a broa e a brevidade da Dona Terezinha, o
                queijo, o requeijão, a manteiga e os doces de leite, de cidra e
                de figo de Dona Conceição de Jair. Também faz parte do
                conhecimento e práticas desenvolvidas em Ponte do Gama as peças
                artesanais produzidas pelo Sr. José de Jair. Trata-se da
                fabricação artesanal em couro principalmente de peças destinadas
                à montaria, além de artesanato feito com a fibra da taquara.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="O saber (conhecimento) e a produção artesanal do Sr. José de Jair"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Batizado como José Silvério dos Santos, filho de uma família de
                Paracatu, o atingido se mudou para o Gama quando tinha 12 anos.
                A família residia em Paracatu de Baixo, mas parte dos parentes
                vivia em Paracatu de Cima e permaneceu morador ali até os dias
                atuais.
              </p>
              <p>
                Sr. José de Jair é assim conhecido por ser filho do Sr. Jair, já
                falecido. Produz manufaturas como cestos, balaios de taquara e
                peças fabricadas artesanalmente em couro, a exemplo de bolsas,
                selas, barrigueira para selas e chicotes. Aprendeu a trabalhar o
                couro observando várias pessoas, bem como por conta própria. A
                execução da técnica de trançar foi passada a ele por um cigano
                que circulou pela região há alguns anos. A venda é feita
                conforme chegam as encomendas e, na maioria das vezes,
                confecciona os artefatos para uso próprio.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-start"
        >
          <img
            width="100%"
            src="../../assets/sobre_cultura_ponte_do_gama_03.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista do interior da Capela de Nossa Senhora Aparecida, em Ponte do
            Gama. Fotos: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-start">
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_cultura_ponte_do_gama_04.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.5em">
            Sr. José de Jair, artesão e morador de Ponte do Gama. Foto: Paula
            Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_cultura_ponte_do_gama_05.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.5em">
            Registro fotográfico da técnica artesanal de trançar, desenvolvida
            pelo Sr. José de Jair. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex justify-center alig-center flex-column"
        >
          <v-img
            src="../../assets/sobre_cultura_ponte_do_gama_06.png"
            max-width="100%"
          ></v-img>
          <p style="text-align: center; font-size: 0.5em">
            Registro fotográfico da técnica artesanal de trançar, desenvolvida
            pelo Sr. José de Jair. Foto: Paula Zanardi, 2021.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid">
      <v-row class="d-flex justify-space-between">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-start"
        >
          <img
            width="100%"
            src="../../assets/sobre_cultura_ponte_do_gama_07.png"
          />
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Atividades tradicionais socieconômicas"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Quanto ao aspecto produtivo da comunidade, as atividades
                econômicas de maior relevância consistem na criação de gado,
                formação de pastagem, plantio de culturas anuais como milho,
                mandioca, cana de açúcar, bem como de culturas perenes, a
                exemplo de frutíferas de espécies variadas. A pesca faz parte
                das práticas extrativistas, da mesma forma que a coleta de lenha
                seca nas matas para abastecer os fogões à lenha. Na região
                evidenciam-se ainda as extensões de cultivo de eucalipto e
                produção de carvão vegetal. Ressalta-se também a atividade de
                garimpo de ouro, desenvolvida desde o período colonial (a partir
                do final do século XVII), perpassando séculos como atividade
                produtiva de grande relevância para a economia local, bem como
                para as famílias que ali viveram ao longo do tempo.
              </p>
              <p>
                Como Ponte do Gama não possui escola entre as estruturas
                públicas, as crianças estudam em Monsenhor Horta ou
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >. No que diz respeito a relações comerciais, os produtos que
                não eram produzidos nos próprios terrenos, os moradores
                adquiriam principalmente no comércio de comunidades próximas
                como Águas Claras, onde fica a Associação dos Produtores de
                Leite, e Paracatu de Baixo.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Capela de Nossa Senhora Aparecida"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Edificada no início da década de 1970 pelos moradores, a capela
                de pequenas proporções apresenta volumetria prismática e planta
                retangular. A cobertura de telhas cerâmicas é disposta em duas
                águas com cumeeira perpendicular à fachada frontal e de fundos,
                formando empena triangular. A fachada frontal apresenta
                simetria, composta por duas janelas e uma porta centralizada,
                sendo as esquadrias com caixilho metálico e fechamento em vidro.
                Ao modo de torre, encontra-se a cruz, inserida no elemento
                decorativo de concreto e forma triangular que parte da fachada e
                emoldura a porta de entrada.
              </p>
            </template>
          </Card>
          <Card
            borderColor="#e06919"
            class="mb-5"
            title="Centro Comunitário"
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                Construído entre 2005 e 2006, a edificação que abriga o Centro
                Cultural, apesar da configuração tipológica atual, incorporou
                elementos das construções do período colonial, a exemplo da
                volumetria prismática simplificada, telhado disposto em duas
                águas com cobertura de telhas cerâmicas. Ao centro da fachada
                frontal localiza-se um vão de acesso, resultando em duas
                porções, sendo que à esquerda (observador posicionado de frente
                para a edificação), encontram-se três janelas de madeira com
                verga reta, demarcadas pelas colunas de madeira do alpendre. A
                porção direita é composta por esquadrias com características
                contemporâneas, de formato quadrado e peitoril alto.
              </p>
              <p class="text-center">
                <strong>Autoria: Ana Paula Alves Ferreira <sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-end align-center"
        >
          <img
            width="100%"
            src="../../assets/sobre_cultura_ponte_do_gama_08.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Vista da fachada frontal da Capela de Nossa Senhora Aparecida. Foto:
            Paula Zanardi, 2021.
          </p>
          <img
            width="40%"
            src="../../assets/sobre_cultura_ponte_do_gama_09.png"
          />
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p class="mb-0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}

.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
